<!-- src/pages/ChatPage.vue -->

<template>
  <v-navigation-drawer 
    expand-on-hover 
    rail
    class="navigation-drawer"
    rail-width="64"
    width="350"
    v-if="!$vuetify.display.mobile"
  >
    <ChatNavigation
      :userPhoto="userPhoto"
      :userName="userName"
      :truncatedUserEmail="truncatedUserEmail"
      @resetChat="resetChat"
      @openChatHistory="openChatHistory"
      @openCustomFiles="openCustomFiles"
      @openLessonHistory="openLessonHistory"
      @openAssistantInfo="openAssistantInformation"
      @openUserProfile="openUserProfile"
      @openAccountSettings="openAccountSettings"
      @signOut="signOut"
    />  
  </v-navigation-drawer>

  <v-app-bar class="top-navigation-bar" density="compact">
    <v-row class="d-flex align-center">
      <v-col cols="2" class="d-flex justify-center">
        <v-menu
          v-model="menu"
          location="end"
          v-if="$vuetify.display.mobile"
        >
          <template v-slot:activator="{ props }">
            <v-btn icon color="primary" v-bind="props">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-card class="menu-card" width="350">
            <ChatNavigation
              :userPhoto="userPhoto"
              :userName="userName"
              :truncatedUserEmail="truncatedUserEmail"
              @resetChat="resetChat"
              @openChatHistory="openChatHistory"
              @openCustomFiles="openCustomFiles"
              @openLessonHistory="openLessonHistory"
              @openAssistantInfo="openAssistantInformation"
              @openUserProfile="openUserProfile"
              @openAccountSettings="openAccountSettings"
              @signOut="signOut"
            /> 
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="8" class="d-flex justify-center">
        <!-- <v-tabs v-model="activeTab" color="primary">
          <v-tab>Chat</v-tab>
          <v-tab @click="handleLogClick">Log</v-tab>
          <v-tab v-if="isInstructor" @click="handleInstructClick">Instruct</v-tab>
        </v-tabs> -->
      </v-col>
      <v-col v-if="!$vuetify.display.mobile" cols="2" class="d-flex justify-center">
        <v-btn
          prepend-icon="mdi-chat-plus"
          color="primary"
          @click="resetChat"
        >
          New Chat
        </v-btn>
      </v-col>
      <v-col v-else cols="2" class="d-flex justify-center">
        <v-btn 
          icon="mdi-chat-plus"
          variant="text"
          color="primary"
          @click="resetChat"
        >
        </v-btn>
      </v-col>
    </v-row>
  </v-app-bar>

  <v-container
    class="main-container custom-background"
  >
    <v-spacer v-if="!isChatActive"/>

    <v-row dense v-if="!isChatActive" class="default-container">
      <v-col cols="12" class="justify-center">
        <v-img src="@/assets/orb-animation.png" class="breathing-image" />
      </v-col>
      <v-col cols="12" align="center">
        <v-card-title class="display-title">Hi, I'm Amelia...</v-card-title>
        <v-card-text class="display-text">Your personal aviation knowledge assisant.</v-card-text>
      </v-col>
      <!-- <v-col 
        cols="12" 
        align="center" 
        class="prompt-row" 
        v-for="(prompt, index) in samplePrompts" 
        :key="index"
      >
        <v-btn
          color="primary"
          :disabled="isAssistantThinking"
          variant="tonal"
          class="text-subtitle-1 prompt-button"
          @click="startChatWithPrompt(prompt)"
          v-if="!$vuetify.display.mobile"
        >
          {{ prompt }}
        </v-btn>
      </v-col> -->
    </v-row>

    <v-spacer v-if="!isChatActive"/>

    <v-row v-else class="message-container" ref="messageContainer">
      <v-col>
        <div v-for="(message, index) in conversation" :key="index" 
          class="message-item" 
          :class="{
            'user-message': message.type === 'user', 
            'assistant-message': message.type === 'assistant',
            'thinking-message': message.type === 'assistant' && message.content === '' && index === activeConversationResponseIndex
          }">
        <v-avatar v-if="message.type === 'user'" @error="onAvatarError" class="avatar user-avatar">
          <v-img :src="userPhoto"></v-img>
        </v-avatar>
        <div class="message-box" 
            :class="{
              'user-box': message.type === 'user', 
              'assistant-box': message.type === 'assistant', 
              'thinking-box': message.type === 'assistant' && message.content === '' && index === activeConversationResponseIndex
            }">
          <div v-if="message.type === 'assistant' && message.content === '' && index === activeConversationResponseIndex" class="typing">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div v-else v-html="parseMarkdown(message.content)"></div>
          <v-btn
            v-if="message.type === 'assistant' && activeConversationResponseIndex !== index"
            class="mb-2 ml-3"
            variant="plain"
            color="error"
            size="x-small"
            text="Report as Inaccurate"
            prepend-icon="mdi-alert"
            @click="reportResponse(message)"
          >
          </v-btn>
        </div>
        <v-avatar v-if="message.type === 'assistant'" class="avatar assistant-avatar">
          <v-img :src="assistantAvatar"></v-img>
        </v-avatar>
      </div>
      </v-col>
    </v-row>

    <v-row dense class="input-container">
      <v-col cols="12">
        <div class="textarea-container">
          <div class="flex-container">
            <v-textarea
              v-model="messageContent"
              class="text-area"
              variant="solo"
              bg-color="rgba(200, 200, 200, 0.2)"
              rounded="xl"
              rows="1"
              no-resize
              hide-details
              flat
              :placeholder="isAssistantThinking ? 'Please wait, your assistant is thinking...' : 'Type your message...'"
              :disabled="isAssistantThinking"
              @keydown.enter.prevent="handleMessageSend"
            >
            <template v-slot:append-inner>
              <v-btn
                variant="flat"
                color="primary"
                size="x-small"
                rounded="pill"
                :disabled="isSendDisabled"
                class="send-button"
                @click="handleMessageSend"
              >
                <v-icon color="white">mdi-send</v-icon>
              </v-btn>
            </template>
          </v-textarea>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-card-text class="subtext-content">Amelia can make mistakes. Consider checking important information.</v-card-text>
      </v-col>
    </v-row>  
  </v-container>

  <v-overlay 
    v-model="isLoadingUser" 
    class="align-center justify-center"
    persistent
  >
  <v-row justify="center">
    <v-col cols="12" class="loading-indicator">
        <v-progress-circular
            indeterminate
            color="primary"
            size="100"
            width="5"
        > <v-icon>mdi-airplane</v-icon>
        </v-progress-circular>
      </v-col>
    </v-row>
  </v-overlay>

  <!-- <v-bottom-sheet v-model="showBottomSheet" inset class="bottom-sheet">
    <v-card
      color="primary"
      height="100%"
      class="text-center"
    >
      <v-card-text>
        <v-card-title>Are You a CFI?</v-card-title>
        <v-card-text>
          Unlock advanced tools to track student progress and gain valuable insights. Enable Instructor Mode in Account Settings to elevate your teaching experience with personalized analytics and streamlined student management.
        </v-card-text>
        <v-btn
          color="white"
          variant="outlined"
          prepend-icon="mdi-cog"
          class="banner-button"
          @click="handleBottomSheetClick"
        >
          Open Settings
        </v-btn>
      </v-card-text>
    </v-card>
  </v-bottom-sheet> -->
  
  <SnackbarNotification 
    :message="snackbar.message" 
    :color="snackbar.color" 
    :timeout="snackbar.timeout" 
    location="top right"
  />
  <ChatHistory ref="chatHistory" @chatSelected="loadChatThread"/>
  <CustomFiles 
    ref="customFiles" 
    @customFileUploaded="() => showSnackbar('Custom file added', 'success')"
    @customFileDeleted="() => showSnackbar('Custom file deleted', 'info')"
    @customFileUploadError="() => showSnackbar('Error adding custom file', 'error')"
    @customFileDeleteError="() => showSnackbar('Error deleting custom file', 'error')"
  />
  <AssistantInformation ref="assistantInformation"/>
  <UserProfile ref="userProfile" @profileUpdated="() => showSnackbar('User profile updated', 'info')"/>
  <AccountSettings
    :userEmail="userEmail"
    :userName="userName"
    ref="accountSettings" 
    @settingsUpdated="() => showSnackbar('Account settings updated', 'info')"
    @signOut="signOut"
  />
</template>

<script>
import { 
  postThread, 
  postChatMessage, 
  getChatStream,
  getThread 
} from '@/services/api';
import { reportInaccurateResponse } from '@/services/firebaseService.js';
import { Sentry } from '@/plugins/sentry.js';
import { analytics } from '../../firebaseConfig.js';
import { logEvent } from 'firebase/analytics';
import ChatNavigation from '@/components/chat/ChatNavigation.vue';
import ChatHistory from '@/components/chat/ChatHistory.vue';
import CustomFiles from '@/components/chat/CustomFiles.vue';
import AssistantInformation from "@/components/chat/AssistantInformation.vue";
import UserProfile from "@/components/other/UserProfile.vue";
import AccountSettings from "@/components/other/AccountSettings.vue";
import SnackbarNotification from '@/components/other/SnackbarNotification.vue';
import { v4 as uuidv4 } from 'uuid';
import MarkdownIt from 'markdown-it';

export default {
  name: 'ChatPage',
  components: {
    ChatNavigation,
    ChatHistory,
    CustomFiles,
    AssistantInformation,
    UserProfile,
    AccountSettings,
    SnackbarNotification
  },
  data () {
    return {
      defaultAvatar: require('@/assets/default-avatar.png'),
      assistantAvatar: require('@/assets/orb-animation.png'),
      isLoading: false,
      menu: false,
      activeTab: 0,
      snackbar: {
        message: '',
        color: '',
        timeout: null,
      },
      showBottomSheet: false,
      isChatActive: false,
      isAssistantThinking: false,
      messageContent: '',
      threadId: null,
      conversation: [],
      samplePrompts: [
        "Can you explain the VFR weather minimums for flying in Class B airspace?",
        "Could you provide the requirements for a pilot to remain current in instrument flying according to the FARs?",
        "What are the principles of flight for a fixed-wing aircraft?"
      ],
      activeConversationResponseIndex: -1
    }
  },
  async created() {
    this.isLoading = true;
    await Promise.all([
      this.$store.dispatch('fetchUser'),
    ]);
    if (!this.isLoadingUser) {
      this.isLoading = false;
    }
  },
  methods: {
    async handleMessageSend() {
      if (this.isSendDisabled) {
        return;
      }
      if (!this.isChatActive) {
        await this.initiateNewChat();
      }
      else {
        this.sendChatMessage(this.messageContent, this.threadId);
      }
    },
    async initiateNewChat() {
      if (this.messageContent.trim() && !this.isChatActive) {
        this.isChatActive = true;
        this.isAssistantThinking = true;
        try {
          const userMessage = this.messageContent;
          this.messageContent = '';
          this.conversation.push({
            type: 'user',
            content: userMessage
          });
          this.activeConversationResponseIndex = this.conversation.push({
            type: 'assistant',
            content: ''
          }) - 1;
          const threadRes = await postThread();
          const threadUid = threadRes.threadUid;
          this.threadId = threadUid;
          await postChatMessage(threadUid, userMessage);
          await getChatStream(threadUid, this);
          logEvent(analytics, 'new_chat_initiated', { userId: this.userUid });
        } catch (error) {
          Sentry.captureMessage("Chat initiation failed");
          Sentry.captureException(error);
          console.error("Chat initiation failed:", error);
          this.showSnackbar('Failed to initiate chat. Please try again.', 'error');
        } finally {
          this.isAssistantThinking = false;
          this.activeConversationResponseIndex = -1;
        }
      }
    },
    async sendChatMessage(messageContent, threadUid) {
      if (!messageContent.trim() || !threadUid) return;
      this.isChatActive = true;
      this.isAssistantThinking = true;
      try {
        const userMessage = this.messageContent;
        this.messageContent = '';
        this.conversation.push({
          type: 'user',
          content: userMessage
        });
        this.activeConversationResponseIndex = this.conversation.push({
          type: 'assistant',
          content: ''
        }) - 1;
        await postChatMessage(threadUid, userMessage);
        await getChatStream(threadUid, this);
        logEvent(analytics, 'user_chat_sent', { userId: this.userUid });
      } catch (error) {
        Sentry.captureMessage("Error sending chat message");
        Sentry.captureException(error);
        console.error('Error sending chat message:', error);
        this.showSnackbar('Failed to send message. Please try again.', 'error');
      } finally {
        this.isAssistantThinking = false;
        this.activeConversationResponseIndex = -1;
      }
    },
    async startChatWithPrompt(prompt) {
      this.isAssistantThinking = true;
      this.messageContent = prompt;
      await this.initiateNewChat();
      logEvent(analytics, 'chat_initiated_with_prompt', { userId: this.userUid });
    },
    resetChat() {
      if (this.isChatActive) {
        this.isChatActive = false;
        this.messageContent = '';
        this.conversation = [];
        this.threadId = null;
        this.isAssistantThinking = false;
      } else {
        this.showSnackbar("New chat already started. Please begin your conversation.", "info");
      }
    },
    async fetchAndDisplayMessages(threadId) {
      try {
        const thread = await getThread(threadId);
        thread.messages.forEach(message => {
          this.conversation.push({
            type: message.type,
            content: message.message
          });
        });
        this.$nextTick(() => {
          if(!this.$refs.messageContainer) return;
          const container = this.$refs.messageContainer.$el;
          container.scrollTop = container.scrollHeight;
        });
      } catch (error) {
        console.error('Error fetching messages from Firestore:', error);
        this.showSnackbar('Error updating conversation from database. Please try again.', 'error');
        this.isAssistantThinking = false;
      }
    },
    async loadChatThread(threadId) {
      this.isChatActive = true;
      this.threadId = threadId;
      this.conversation = [];
      this.citationMap = {};
      this.annotationCount = 0;
      try {
        await this.fetchAndDisplayMessages(threadId);
      } catch (error) {
        console.error('Error loading chat thread:', error);
        this.snackbar.message = 'Failed to load chat history. Please try again.';
        this.snackbar.color = 'error';
        this.snackbar.timeout = 5000;
      }
    },
    async reportResponse(message) {
      const reportId = uuidv4();
      try {
        await reportInaccurateResponse(reportId, this.threadId, message, this.userUid);
        this.showSnackbar('Inaccurate response reported to FlightSense', 'info');
      } catch (error) {
        this.showSnackbar('Failed to report inaccurate response', 'error');
      }
    },
    parseMarkdown(text) {
      if (!text) return '';
      const md = new MarkdownIt().use(require('markdown-it-footnote'));
      md.renderer.rules.footnote_ref = (tokens, idx) => {
        const n = Number(tokens[idx].meta.id + 1).toString();
        const id = 'fn' + n;
        return `<sup id="fnref${n}" class="footnote-ref"><a href="#${id}">[${n}]</a></sup>`;
      };
      md.renderer.rules.footnote_anchor = (tokens, idx) => {
        const n = Number(tokens[idx].meta.id + 1).toString();
        return ` <a href="#fnref${n}" class="footnote-backref">↩</a>`;
      };
      md.renderer.rules.footnote_anchor = () => '';
      md.renderer.rules.footnote_close = () => '</li>';
      md.renderer.rules.footnote_block_open = () => (
        `<details class="footnotes"><summary><i class="mdi mdi-book-open-page-variant"></i> View Citations</summary><section class="footnotes-content"><ol class="footnotes-list">\n`
      );
      md.renderer.rules.footnote_block_close = () => '</ol></section></details>\n';
      const properlyEscapedText = text
        .replace(/\\\[/g, '\n\\\\[')  // Replace '\[' with '\\[' for MathJax
        .replace(/\\\]/g, '\\\\]\n')  // Replace '\]' with '\\]' for MathJax
        .replace(/%/g, '\\%')       // Escape percentage signs to avoid LaTeX comment behavior
        .replace(/_/g, '\\_')       // Escape underscores in LaTeX variable names like \rho_0
        .replace(/\\\(/g, '\\\\(')  // Ensure inline LaTeX \( ... \) is properly escaped
        .replace(/\\\)/g, '\\\\)'); // Ensure inline LaTeX \( ... \) is properly escaped
      const renderedContent = md.render(properlyEscapedText);
      this.$nextTick(() => {
        if (window.MathJax) {
          window.MathJax.typeset();
        }
      });
      return renderedContent;
    },
    handleLogClick(event) {
      if (event.metaKey || event.ctrlKey) {
        window.open('/log', '_blank');
      } else {
        this.$router.push('/log');
      }
    },
    handleInstructClick(event) {
      if (event.metaKey || event.ctrlKey) {
        window.open('/instruct', '_blank');
      } else {
        this.$router.push('/instruct');
      }
    },
    handleBottomSheetClick() {
      this.showBottomSheet = false;
      this.openAccountSettings();
    },
    openChatHistory() {
      this.$refs.chatHistory.open(this.userUid);
    },
    openCustomFiles() {
      this.$refs.customFiles.open(this.userUid);
    },
    openLessonHistory() {
      this.$refs.lessonHistory.open(this.userUid);
    },
    openAssistantInformation() {
      this.$refs.assistantInformation.open();
    },
    openUserProfile() {
      this.$refs.userProfile.open(this.userUid);
    },
    openAccountSettings() {
      this.$refs.accountSettings.open(this.isInstructor);
    },
    async signOut() {
      try {
        await this.$store.dispatch('signOut');
        this.$router.push('/');
      } catch (error) {
        console.error('Error signing out:', error);
      }
    },
    showSnackbar(message, color) {
      this.snackbar.message = '';
      this.$nextTick(() => {
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.timeout = 8000;
      });
    },
    onAvatarError(event) {
      event.target.src = this.defaultAvatar;
    }
  },
  computed: {
    isLoadingUser() {
      return this.$store.getters.isLoadingUser && this.isLoading === false;
    },
    user() {
      return this.$store.getters.user;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isInstructor() {
      return this.$store.state.user ? this.$store.state.user.isInstructor : false;
    },
    userUid() {
      return this.$store.state.user ? this.$store.state.user.uid : null;
    },
    userEmail() {
      return this.$store.state.user ? this.$store.state.user.email : '';
    },
    userName() {
      return this.$store.state.user ? this.$store.state.user.displayName : '';
    },
    userPhoto() {
      return this.$store.state.user && this.$store.state.user.photoUrl ? this.$store.state.user.photoUrl : this.defaultAvatar;
    },
    dateCreated () {
      return this.$store.state.user ? this.$store.state.user.dateCreated : null;
    },
    lastLogin () {
      return this.$store.state.user ? this.$store.state.user.lastLogin : null;
    },
    isFirstLogin() {
      if (!this.dateCreated) {
        return false;
      }
      const createdDate = this.dateCreated._seconds * 1000 + Math.floor(this.dateCreated._nanoseconds / 1e6);
      const currentTime = Date.now();
      const oneMinuteInMs = 60 * 1000;
      const isWithinOneMinute = Math.abs(currentTime - createdDate) <= oneMinuteInMs;
      return isWithinOneMinute;
    },
    truncatedUserEmail() {
      const maxLength = 24;
      if (this.userEmail && this.userEmail.length > maxLength) {
        return this.userEmail.substring(0, maxLength - 3) + '...';
      }
      return this.userEmail;
    },
    isSendDisabled() {
      return this.messageContent.trim().length === 0;
    },
    lastMessage() {
      if (this.conversation.length === 0) {
        return '';
      }
      return this.conversation[this.conversation.length - 1].content;
    },
    instructorBottomSheet() {
      return this.isFirstLogin && !this.isInstructor;
    }
  },
  watch: {
    dateCreated(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.showBottomSheet = this.instructorBottomSheet;
        });
      }
    },
    lastMessage() {
      this.$nextTick(() => {
        if(!this.$refs.messageContainer) return;
        const container = this.$refs.messageContainer.$el;
        container.scrollTop = container.scrollHeight;
      });
    }
  }
}
</script>

<style scoped>
.top-navigation-bar {
  background-color: rgba(200, 200, 200, 0.2);
  backdrop-filter: blur(10px);
}

.custom-background {
  background-color: rgb(var(--v-theme-background));
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 93.5vh;
}

.loading-indicator {
  padding-left: 64px;
}

.display-title {
  color: rgb(var(--v-theme-primary));
  font-weight: bold;
  font-size: 30px;
}

.display-text {
  color: rgb(var(--v-theme-primary));
  font-size: 16px;
  padding: 2px 0;
  text-align: center;
}

.breathing-image {
  height: 20vh;
  animation: breathingAnimation 3s ease-in-out infinite;
  margin-top: 40px;
}

.navigation-drawer {
  background-color: rgba(var(--v-theme-primary), 0.8);
  backdrop-filter: blur(10px);
}

:deep(.menu-card) {
  background-color: rgba(var(--v-theme-primary), 0.8) !important;
  backdrop-filter: blur(10px);
}

.default-container {
  flex-grow: 1;
  margin-inline: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.message-container {
  flex-grow: 1;
  overflow-y: auto;
  margin-inline: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
}

:deep(.message-container) p {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.message-container) h1 {
  margin-inline: 20px;
  margin-block: 20px;
}

:deep(.message-container) h2 {
  margin-inline: 20px;
  margin-block: 20px;
}

:deep(.message-container) h3 {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.message-container) h4 {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.message-container) h5 {
  margin-inline: 20px;
  margin-block: 10px;
}

:deep(.message-container) ul {
  margin-inline: 30px;
  margin-block: 10px;
}

:deep(.message-container) ol {
  margin-inline: 30px;
  margin-block: 10px;
}

:deep(.message-container) li {
  margin-inline: 30px;
  margin-block: 10px;
}

:deep(.message-container) hr {
  margin-inline: 20px;
  border: none;
  height: 2px;
  background-color: white;
}

:deep(.message-container) table {
  margin-inline: 30px;
  margin-block: 20px;
  width: 90%;
  border-collapse: collapse;
}

:deep(.message-container) th {
  padding-block: 10px;
  text-align: center;
  border: 1px solid white;
}

:deep(.message-container) td {
  padding-block: 5px;
  text-align: center;
  font-size: 14px;
  border: 1px solid white;
}

:deep(.message-container) pre {
  margin-inline: 20px;
  margin-block: 20px;
  padding: 10px;
  background-color: #282c34;
  color: #f8f8f2;
  border-radius: 5px;
  overflow: auto;
}

:deep(.message-container) code {
  width: 100%;
  background-color: #4b4d52;
  color: #f8f8f2;
  padding: 2px 4px;
  border-radius: 3px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
}

:deep(.message-container) pre code {
  padding: 0;
}

:deep(.message-container) mjx-container {
  background-color: rgba(var(--v-theme-primary), 0.1);
  padding: 10px;
  margin: 5px;
  border-radius: 3px;
  display: inline-flex;
  justify-content: flex-start;
  width: auto;
  max-width: 100%;
  box-sizing: border-box;
}

:deep(.message-container) mjx-math {
  color: #f8f8f2;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 400;
  white-space: normal;
}

:deep(.message-container) mjx-mtext,
:deep(.message-container) mjx-mn,
:deep(.message-container) mjx-mo {
  color: #f8f8f2;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 10px;
  word-wrap: break-word;
}

.message-container::-webkit-scrollbar {
  width: 5px;
}

.message-container::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-primary), 0.1);
  border-radius: 10px;
}

.message-container::-webkit-scrollbar-thumb {
  background: rgba(var(--v-theme-primary), 0.3);
  border-radius: 10px;
}

.message-container::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-primary), 0.8);
}

.message-item {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  width: 100%;
}

.message-box {
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.user-message .message-box {
  background-color: rgba(var(--v-theme-primary), 0.8);
  color: white;
  margin-left: 10px; /* Space between avatar and message box */
  order: 1; /* Message box after avatar */
  border-bottom-left-radius: 0;
}

.assistant-message .message-box {
  background-color: rgba(var(--v-theme-secondary), 0.8);
  color: white;
  margin-right: 10px; /* Space between avatar and message box */
  order: 2; /* Message box before avatar */
  border-bottom-right-radius: 0;
}

.assistant-message .thinking-box {
  background-color: rgba(var(--v-theme-secondary), 0.8) !important;
  padding: 5px;
  min-height: 40px;
  width: 100px;
  margin-left: auto;
  border-radius: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thinking-message .assistant-avatar {
  opacity: 0.8;
}

.typing {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.typing span {
  display: block;
  height: 10px;
  width: 10px;
  background-color: rgba(var(--v-theme-background));
  border-radius: 50%;
  margin: 0 2px;
  animation: blink 1.5s infinite;
  animation-fill-mode: both;
}

.typing span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.user-avatar {
  order: 0; /* Avatar before message box */
}

.assistant-avatar {
  order: 3; /* Avatar after message box */
}

:deep(.footnotes) {
  margin-block: 20px;
  margin-inline: 20px;
  background-color: rgba(var(--v-theme-background), 0.2);
  border-radius: 5px;
  padding: 10px;
  padding-left: 20px;
}

:deep(.footnote-ref a) {
  color: rgba(var(--v-theme-primary)) !important;
}

:deep(.footnotes-content) {
  margin-block: 20px;
  color: #f8f8f2;
}

:deep(.footnotes-content ol) {
  margin-inline: 10px;
  margin-block: 5px;
}

:deep(.footnotes summary) {
  cursor: pointer;
}

:deep(.footnotes summary::marker) {
  content: none;
}

.input-container {
  align-self: center;
  justify-content: center;
  width: 100%;
  max-height: 100px;
}

.subtext-content {
  align-self: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  color: rgba(var(--v-theme-primary));
}

.textarea-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flex-container {
  display: flex;
  position: relative;
  width: 100%;
}

.text-area {
  flex-grow: 1;
  cursor: text;
}

:deep(.v-field__input::-webkit-scrollbar) {
  display: none;
}

:deep(.v-field) {
  padding-right: 55px !important;
  color: rgba(var(--v-theme-primary));
}

.send-button {
  height: 30px;
  position: absolute;
  cursor: pointer;
  padding-top: 3px;
  padding-left: 10px;
}

.send-button-placeholder {
  height: 30px;
  position: absolute;
  padding-top: 3px;
  padding-left: 20px;
}

.message-subtext {
  font-weight: 300;
  font-size: 14px;
  color: rgba(var(--v-theme-primary));
  text-align: center;
  padding-top: 2px;
}

.prompt-row {
  height: 20px;
}

.prompt-button {
  font-weight: 300;
  font-size: 14px;
  color: rgba(var(--v-theme-primary));
  text-align: center;
}

.logout-button {
  margin-top: 10px;
  margin-bottom: 20px;
}

.bottom-sheet {
  height: 200px;
}

@keyframes breathingAnimation {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@media (max-width: 1024px) {
  .main-container {
    height: 95vh;
  }

  :deep(.message-container pre) {
    margin-inline: 10px;
  }

  :deep(.message-container code) {
    white-space: normal;
    text-wrap: break-word;
  }

  .loading-indicator {
    padding-left: 0px;
  }

  .bottom-sheet {
    height: 225px;
  }
}

@media (max-width: 600px) {
  .main-container {
    height: 93.5vh;
  }

  .display-title {
    font-size: 20px;
  }

  .display-text {
    font-size: 14px;
  }

  .avatar {
    height: 20px;
    width: 20px;
  }

  .message-container {
    font-size: 14px;
  }

  :deep(.message-container p),
  :deep(.message-container h1),
  :deep(.message-container h2),
  :deep(.message-container h3),
  :deep(.message-container h4),
  :deep(.message-container h5) {
    margin-inline: 10px;
  }

  :deep(.message-container ul),
  :deep(.message-container ol),
  :deep(.message-container li) {
    margin-inline: 12.5px;
  }

  :deep(.message-container table) {
    margin-inline: 15px;
  }

  :deep(.message-container th),
  :deep(.message-container td) {
    font-size: 12px;
  }

  .message-container::-webkit-scrollbar {
    display: none;
  }

  :deep(.footnotes) {
    margin-inline: 10px;
  }

  .subtext-content {
    font-size: 10px;
  }

  .bottom-sheet {
    height: 250px;
  }
}
</style>
